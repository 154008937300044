import React, { useState, useEffect } from 'react'
import { FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
import Spinner from 'components/common/Spinner'
import { useRouter } from 'next/router'

import Text from 'components/common/Text'
import FormInput from 'components/common/forms/elements/FormInput'
import axios from 'utility/axios'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

import Card from 'components/common/cards/Card'
import { COLORS } from 'utility/colors'
import Button from 'components/common/Button'
import { POST_MAGICLINK } from 'utility/endpoints'
import colors from 'utility/colors'
import { toast } from 'react-toastify'

const SignInCard = ({
	title = 'Welcome back!',
	subtitle = "Enter your email address and we'll send you a ONE-TIME-USE magic link to get you authenticated and securely signed in.",
}) => {
	const { data: tenantProfile } = useTenantProfile()
	const primaryColor = tenantProfile?.theme?.primaryColor

	const router = useRouter()
	const { error: routerError } = router?.query

	const [error, setError] = useState()
	const [loading, setLoading] = useState(false)
	const [success, setSuccess] = useState()

	const formik = useFormik({
		initialValues: {
			email: '',
		},
		onSubmit: async (values, { resetForm }) => {
			// If already loading, then stop the form.
			if (loading) return
			setError(null)
			setSuccess(null)
			setLoading(true)

			const { email } = values

			try {
				let data = {
					emailAddress: email,
				}
				await axios.post(POST_MAGICLINK, data)
			} catch (err) {
				logger.error('Magic Link Error', JSON.stringify(err))
				setError(
					`Sorry, there's an unknown system error. Please ensure you've filled out a valid email. Sometimes trying a different browser or credit card helps. If it still doesn't work, please reach out to our support team via the webchat feature on our website.`,
				)
			} finally {
				resetForm()
				setLoading(false)
				setSuccess(email)
				toast.success(
					'If you have an account, we sent you a magic link to sign in. Please check your email.',
				)
			}
		},
		validationSchema: Yup.object({
			email: Yup.string().email('Invalid email address').required('Required'),
		}),
		validateOnChange: true,
		validateOnBlur: true,
	})

	const labelColorStyle = {
		color: COLORS.GRAY,
		fontWeight: 'bold',
		fontSize: '14px',
		marginBottom: 7,
	}

	const formSpacing = {
		marginBottom: 20,
	}

	const errorStyle = {
		color: colors.RED,
	}

	return (
		<Card noShadow>
			<div style={{ marginBottom: 20 }}>
				<Text bold size={24} align='center'>
					{success ? (
						<>Your secure magic link is on the way!</>
					) : routerError ? (
						<>Magic link expired</>
					) : (
						<>{title}</>
					)}
				</Text>
				<Text size={14} align='center'>
					{success ? (
						<>
							To verify your identity, we've sent an email to{' '}
							<span style={{ color: primaryColor, fontWeight: 'bold' }}>{success}</span>. Please
							check your inbox and click the magic link to sign in! If you entered an email not in
							our system you will not receive an email.
						</>
					) : routerError ? (
						<>Please request a new ONE-TIME-USE magic link to login to your account.</>
					) : (
						<>{subtitle}</>
					)}
				</Text>
			</div>

			<FormikProvider value={formik}>
				<form onSubmit={formik.handleSubmit}>
					<FormInput
						labelStyle={labelColorStyle}
						formContainerStyle={formSpacing}
						placeholder={'hello@example.com'}
						type='text'
						id='email'
						name='email'
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						value={formik.values.email}
						required
						errorStyle={errorStyle}
						error={formik.touched.email && formik.errors.email}
					/>

					{error && (
						<div>
							<Text
								color={'red'}
								align={'left'}
								style={{ padding: 0, margin: 0, marginBottom: 20 }}
							>
								{error}
							</Text>
						</div>
					)}
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							alignItems: 'flex-end',
						}}
					>
						<Button
							style={{
								// width: 'auto',
								paddingLeft: 30,
								paddingRight: 30,
								height: 40,
							}}
							type='submit'
							id='submit-confirm-publish'
							disabled={loading}
							background={primaryColor}
						>
							{loading ? (
								<>
									<Text style={{ paddingBottom: 0, marginBottom: 0 }} color={COLORS.WHITE}>
										Submitting...{' '}
										<Spinner size={15} style={{ paddingLeft: 10 }} color={COLORS.WHITE} />
									</Text>{' '}
								</>
							) : (
								'Send Magic Link'
							)}
						</Button>
					</div>
				</form>
			</FormikProvider>
		</Card>
	)
}

export default SignInCard
