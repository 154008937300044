import ResponsiveImage from 'components/common/ResponsiveImage'

import Link from 'components/common/Link'
import Icon from 'components/common/Icon'
import Text from 'components/common/Text'
import { useSelf } from 'components/providers/SelfProvider'
import { useState, useRef } from 'react'
import colors from 'utility/colors'
import useOnClickOutside from 'hooks/useOnClickOutside'
import { useApp } from 'components/providers/AppProvider'
import { useRouter } from 'next/router'
import { useUser, useUserNotifications } from 'hooks/api/user/useUser'
import { useHasMoreThanDefaultGroups } from 'hooks/api/useHasMoreThanDefaultGroups'
import { useTenantProfile } from 'hooks/api/tenant/useTenantProfile'

import Button from '../Button'

const SidebarTitle = ({ children }) => {
	return (
		<Text size={14} bold color={'#828282'} style={{ margin: 0, padding: 0 }}>
			{children}
		</Text>
	)
}

const SidebarSubtitle = ({ children }) => {
	return (
		<Text size={12} color={'#828282'}>
			{children}
		</Text>
	)
}

const SidebarUser = ({ user, isOwnPortal = true }) => {
	const { breakpoints } = useApp()

	const ref = useRef()
	const [open, setOpen] = useState(false)
	const { isAdmin, logOut } = useSelf()

	useOnClickOutside(ref, () => setOpen(false))

	const adminSubtitle = isAdmin ? <Text size={10}>admin</Text> : ''

	const email = user?.profile?.emailAddress
	const firstName = user?.profile?.firstName
	const lastName = user?.profile?.lastName

	return (
		<div
			ref={ref}
			onClick={() => isOwnPortal && setOpen(!open)}
			style={{
				backgroundColor: 'white',
				padding: 15,
				borderRadius: 10,
			}}
		>
			{open ? (
				<div>
					<div
						style={{
							marginBottom: 20,
							cursor: 'pointer',
						}}
					>
						{/* <SidebarLink to="/profile" note={<div>3</div>}>
              Notifications
            </SidebarLink> */}
						{/* <SidebarLink to="/profile">Account</SidebarLink> */}
						{isOwnPortal && (
							<SidebarLink
								onClick={logOut}
								subtitle={adminSubtitle}
								textStyle={{ textAlign: 'right' }}
							>
								Log Out
							</SidebarLink>
						)}
					</div>
				</div>
			) : null}
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: 10,
					cursor: 'pointer',
				}}
			>
				<div
					style={{
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
					}}
				>
					<Text
						size={14}
						bold
						style={{
							padding: 0,
							margin: 0,
						}}
					>
						{firstName} {breakpoints.lg ? lastName : null}
					</Text>
					<Text size={10} style={{ padding: 0, margin: 0 }}>
						{email}
					</Text>
				</div>
				{isOwnPortal && (
					<div
						style={{
							flex: 1,
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'flex-end',
						}}
					>
						<Icon icon={open ? 'chevron-down' : 'chevron-up'} solid size={12} />
					</div>
				)}
			</div>
		</div>
	)
}

const SidebarHeader = ({ user, onClose }) => {
	const { breakpoints } = useApp()
	const { data: tenantProfile } = useTenantProfile()
	const secondaryColor = tenantProfile?.theme?.secondaryColor
	const tenantLogo = tenantProfile?.theme?.colorLogo

	const userPartner = user?.primaryGroupAccount?.partner?.partnerId
		? user?.primaryGroupAccount?.partner
		: user?.primaryPartnerAccount

	let content = (
		<>
			<Link to='/'>
				<ResponsiveImage
					src={tenantLogo}
					style={{
						maxHeight: '50px',
						maxWidth: '140px',
						marginTop: 4,
						cursor: 'pointer',
					}}
				/>
			</Link>
		</>
	)

	const homeLink = user?.baseSlug

	if (userPartner?.slug) {
		const isMobile = !breakpoints.md

		content = (
			<Link to={homeLink} style={{ cursor: 'pointer' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'row-reverse' : 'column',
						gap: 10,
						alignItems: isMobile ? 'center' : 'flex-start',
					}}
				>
					{userPartner.avatarImageUrl ? (
						<ResponsiveImage
							src={userPartner.avatarImageUrl}
							style={{
								// maxHeight: '100%',
								borderRadius: 10,
								maxWidth: isMobile ? 80 : 185,
								aspectRatio: 1,
								width: '100%',
								marginTop: 4,
							}}
						/>
					) : null}
					<div>
						<Text
							size={12}
							align={isMobile ? 'right' : 'left'}
							style={{ marginBottom: 0, paddingBottom: 0 }}
						>
							My Partner
						</Text>
						<Text
							bold
							color={secondaryColor}
							size={18}
							align={isMobile ? 'right' : 'left'}
							style={{ marginBottom: 0, paddingBottom: 0 }}
						>
							{userPartner.fullName}
						</Text>
						{userPartner.companyName && (
							<Text
								size={14}
								align={isMobile ? 'right' : 'left'}
								style={{
									marginTop: 3,
									marginBottom: 0,
									paddingBottom: 0,
									lineHeight: '16px',
								}}
							>
								{userPartner.companyName}
							</Text>
						)}
					</div>
				</div>
			</Link>
		)
	}

	const horizontalPadding = 20 // breakpoints.lg ? 20 : 15
	return (
		<div
			style={{
				width: '100%',
				padding: horizontalPadding,
				zIndex: 99,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				gap: 50,
			}}
		>
			{!breakpoints.md && (
				<div onClick={onClose} style={{ cursor: 'pointer' }}>
					<Icon icon='times' light />
				</div>
			)}
			{content}
		</div>
	)
}

const SidebarLink = ({ children, subtitle, icon, note, to, onClick, textStyle = {}, isActive }) => {
	const router = useRouter()

	const pathSplitByQuery = router.asPath.split('?')
	const active = isActive !== undefined ? isActive : pathSplitByQuery[0] === to

	const textColor = active ? colors.GRAY : '#333333'
	const iconColor = active ? colors.GRAY : '#BDBDBD'

	let content = (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				cursor: 'pointer',
				alignItems: 'center',
				backgroundColor: active ? colors.WHITE : null,
				padding: 10,
				paddingLeft: 15,
				paddingRight: 15,
				borderRadius: 10,
				boxShadow: active ? '0 2px 10px 0 rgba(44, 44, 44, .1)' : 'none',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'row',
					gap: 15,
					alignItems: 'center',
				}}
			>
				{icon && <Icon color={iconColor} icon={icon} size={14} solid />}
				<div>
					<Text size={14} bold color={textColor} style={{ margin: 0, padding: 0, ...textStyle }}>
						{children}
					</Text>
					{subtitle && subtitle}
				</div>
			</div>
			{note ? (
				<div
					style={{
						// border: `1px solid ${colors.RED}`,
						backgroundColor: colors.RED,
						borderRadius: 100,
						aspectRatio: 1,
						width: 18,
						height: 18,
						justifyContent: 'center',
						alignItems: 'center',
						display: 'flex',
					}}
				>
					<Text
						size={11}
						bold
						align='center'
						color={colors.WHITE}
						style={{ margin: 0, padding: 0, ...textStyle }}
					>
						{note}
					</Text>
				</div>
			) : null}
		</div>
	)

	if (to) {
		return <Link to={to}>{content}</Link>
	}

	return <div onClick={onClick}>{content}</div>
}

const SidebarLinksContainer = ({ children }) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				marginTop: 15,
			}}
		>
			{children}
		</div>
	)
}

const SidebarSection = ({ title, subtitle, children, defaultOpen = false }) => {
	const [open, setOpen] = useState(defaultOpen)

	return (
		<div>
			<div onClick={() => setOpen(!open)} style={{ cursor: 'pointer' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						alignItems: 'center',
					}}
				>
					<SidebarTitle>{title}</SidebarTitle>
					<Icon
						icon={open ? 'chevron-down' : 'chevron-right'}
						solid
						size={open ? 12 : 8}
						color={'#828282'}
					/>
				</div>
				{subtitle && <SidebarSubtitle>{subtitle}</SidebarSubtitle>}
			</div>
			{children && open && <SidebarLinksContainer>{children}</SidebarLinksContainer>}
		</div>
	)
}

const SidebarLinks = ({ user, isLoading }) => {
	const router = useRouter()
	const { data: tenantProfile } = useTenantProfile()
	const primaryColor = tenantProfile?.theme?.primaryColor

	const userSlug = user?.userSlugId
	const hasMoreThanDefaultGroups = useHasMoreThanDefaultGroups(userSlug)
	const groupAccounts = user?.groupAccounts
	const partnerAccounts = user?.partnerAccounts

	const { data: notifications } = useUserNotifications(userSlug)

	if (!user && isLoading) return null

	const firstGroup = groupAccounts?.[0]

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				marginTop: 20,
				gap: 30,
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
				<Text size={11} bold color={colors.SIDEBAR_TEXT_GRAY}>
					Owner Account
				</Text>
				{groupAccounts && groupAccounts.length ? (
					<SidebarLink
						icon='list'
						to={user?.baseSlug}
						isActive={
							(!hasMoreThanDefaultGroups && router.asPath.includes('/manage')) ||
							router.asPath.split('?')[0] === user?.baseSlug
						}
					>
						Properties
					</SidebarLink>
				) : (
					<Link to='/signup'>
						<Button background={primaryColor}>Sign Up My Home</Button>
					</Link>
				)}

				{hasMoreThanDefaultGroups ? (
					<SidebarLink
						icon='layer-group'
						to={`${user?.baseSlug}/groups`}
						isActive={router.asPath.includes('/groups')}
					>
						Groups & Billing
					</SidebarLink>
				) : null}

				<SidebarLink
					icon='bell'
					to={`${user?.baseSlug}/notifications`}
					note={notifications?.length}
				>
					Notifications
				</SidebarLink>

				{!hasMoreThanDefaultGroups && (
					<SidebarLink
						icon='file-invoice-dollar'
						to={`${user.baseSlug}/groups/${firstGroup?.slugId}/billing`}
						isActive={router.asPath.includes('/billing')}
					>
						Billing
					</SidebarLink>
				)}

				<SidebarLink icon='user' to={`${user?.baseSlug}/profile`}>
					Profile
				</SidebarLink>
			</div>

			{partnerAccounts?.map((partnerAccount) => {
				const partnerBaseLink = partnerAccount.link
				// const isDefaultOpen = true // router.asPath.includes(partnerAccount.slugId)
				const isEnterprise = partnerAccount?.partnerCategory === 'Enterprise' ? true : false

				const isTaxAgent = !!partnerAccount?.isGridsPartner
				
				return (
					// Partner Account
					<div
						key={partnerAccount?.slug}
						style={{ display: 'flex', flexDirection: 'column', gap: 0 }}
					>
						<Text size={11} bold color={colors.SIDEBAR_TEXT_GRAY}>
							{isEnterprise ? 'Enterprise ' : 'Partner '} Account
						</Text>
						{partnerAccounts.length > 1 && (
							<Text size={11} bold color={colors.SIDEBAR_TEXT_GRAY}>
								{partnerAccount?.slug}
							</Text>
						)}

						<SidebarLink
							icon='list'
							to={`${partnerBaseLink}/client-referrals`}
							isActive={router.asPath === `${partnerBaseLink}?tab=Properties` || router.asPath === `${partnerBaseLink}?tab=Commissions` || router.asPath.includes(`${partnerBaseLink}/client-referrals`)}
						>
							Client Referrals
						</SidebarLink>
						{isEnterprise && (
							<SidebarLink
								icon='users'
								to={`${partnerBaseLink}/team`}
								isActive={router.asPath.includes(`${partnerBaseLink}/team`)}
							>
								Team
							</SidebarLink>
						)}

						{tenantProfile?.features?.taxToolbox ? (
							<SidebarLink
								icon='megaphone'
								to={`${partnerBaseLink}/marketing`}
								isActive={router.asPath.includes(`${partnerBaseLink}/marketing`)}
							>
								Tax Toolbox
							</SidebarLink>
						) : null}
						<SidebarLink
							icon='user'
							to={`${partnerBaseLink}/profile`}
							isActive={router.asPath.includes(`${partnerBaseLink}/profile`)}
						>
							Profile
						</SidebarLink>
						{isTaxAgent ? (
							<SidebarLink
								icon='table'
								to={`${partnerBaseLink}/grids`}
								isActive={router.asPath.includes(`${partnerBaseLink}/grids`)}
							>
								Grids
							</SidebarLink>
						) : null}
					</div>
				)
			})}
		</div>
	)
}

export const Sidebar = ({ width, onClose }) => {
	const { breakpoints } = useApp()
	const { user: self, isAdmin } = useSelf()
	const router = useRouter()
	const { userSlug } = router?.query

	const { data: slugUser, isLoading } = useUser(userSlug)

	const user = slugUser || self

	const isOwnPortal = self?.userSlugId === user?.userSlugId

	const adminBar = (
		<Link to={self?.baseSlug}>
			<div
				style={{
					backgroundColor: colors.RED,
					padding: 5,
				}}
			>
				<Text
					align='center'
					size={10}
					color={colors.WHITE}
					style={{ paddingBottom: 0, marginBottom: 0 }}
				>
					Viewing as admin
				</Text>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flexDirection: 'row',
						gap: 10,
					}}
				>
					<Icon icon='chevron-left' color={colors.WHITE} size={6} />
					<Text
						align='center'
						size={10}
						bold
						color={colors.WHITE}
						style={{ paddingBottom: 0, marginBottom: 0 }}
					>
						My Account
					</Text>
				</div>
			</div>
		</Link>
	)

	const horizontalPadding = 15 //breakpoints.lg ? 20 : 15

	return (
		<div
			style={{
				position: 'fixed',
				height: '100%',
				width: width,
				minWidth: width,
				backgroundColor: colors.SIDEBAR_GRAY,
			}}
		>
			<div
				style={{
					display: 'flex',
					flex: 1,
					height: '100%',
					justifyContent: 'space-between',
					flexDirection: 'column',
				}}
			>
				<SidebarHeader user={user} onClose={onClose} />

				<div
					style={{
						overflow: 'scroll',
						flex: 1,
						padding: `0px ${horizontalPadding}px`,
					}}
				>
					<SidebarLinks user={user} isLoading={isLoading} />
				</div>
				<div
					style={{
						padding: breakpoints.lg ? `10px ${horizontalPadding}px` : `10px ${horizontalPadding}px`,
					}}
				>
					<SidebarUser user={user} isOwnPortal={isOwnPortal} />
				</div>
				{!isOwnPortal && isAdmin && adminBar}
			</div>
		</div>
	)
}
